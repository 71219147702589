var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("ul", { attrs: { id: "Sticky" } }, [
          _c("li", [
            _c(
              "button",
              {
                staticClass: "btn btn-add rounded-pill float-right mr-1 mt-1",
                attrs: {
                  type: "button",
                  "data-toggle": "tooltip",
                  "data-placement": "left",
                  title: "Ajouter",
                  disabled: _vm.addRM
                },
                on: { click: _vm.addBtn }
              },
              [_c("i", { staticClass: "fas fa-plus text-white" })]
            )
          ]),
          _c("li", [
            _c(
              "button",
              {
                staticClass: "btn btn-edit rounded-pill float-right mr-1 mt-1",
                attrs: {
                  type: "button",
                  "data-toggle": "tooltip",
                  "data-placement": "left",
                  title: "Modifier",
                  disabled:
                    _vm.editRM ||
                    (_vm.maintenanceRequestData.statusPlain != "draft" &&
                      _vm.maintenanceRequestData.statusPlain !=
                        "supervisor_approved")
                },
                on: { click: _vm.editBtn }
              },
              [_c("i", { staticClass: "fas fa-pencil-alt text-white" })]
            )
          ]),
          _c("li", [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-delete rounded-pill float-right mr-1 mt-1",
                attrs: {
                  type: "button",
                  "data-toggle": "tooltip",
                  "data-placement": "left",
                  title: "supprimer",
                  disabled:
                    _vm.deleteRM ||
                    (_vm.maintenanceRequestData.statusPlain != "draft" &&
                      _vm.maintenanceRequestData.statusPlain !=
                        "supervisor_approved")
                },
                on: {
                  click: function($event) {
                    return _vm.deleteR()
                  }
                }
              },
              [_c("i", { staticClass: "far fa-trash-alt text-white" })]
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "div",
              { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 col-md-4" }, [
                    _c("div", { staticClass: "btn-group col-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          class: { active: _vm.etat == "draft" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ListRequests("draft")
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-file-signature" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "badge bg-secondary text-white" },
                            [_vm._v(_vm._s(_vm.drafts.length))]
                          ),
                          _vm._v(" Brouillon ")
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          class: { active: _vm.etat == "supervisor_approved" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ListRequests("supervisor_approved")
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-check text-info" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "badge bg-info text-white" },
                            [_vm._v(_vm._s(_vm.confirms.length))]
                          ),
                          _vm._v(" Confirmé ")
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          class: { active: _vm.etat == "approved" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ListRequests("approved")
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-check-double text-success"
                          }),
                          _c(
                            "span",
                            { staticClass: "badge bg-success text-white ml-1" },
                            [_vm._v(" " + _vm._s(_vm.valids.length))]
                          ),
                          _vm._v(" Validé ")
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          class: { active: _vm.etat == "rejected" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ListRequests("rejected")
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-ban text-danger" }),
                          _c(
                            "span",
                            { staticClass: "badge bg-danger text-white ml-1" },
                            [_vm._v(" " + _vm._s(_vm.rejects.length))]
                          ),
                          _vm._v(" Rejeté ")
                        ]
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-12 col-md-3" },
                    [
                      _c("Multiselect", {
                        attrs: {
                          options: _vm.user.lifebase,
                          multiple: true,
                          "close-on-select": false,
                          "clear-on-select": false,
                          "preserve-search": true,
                          "select-label": "",
                          "deselect-label": "",
                          placeholder: "Selectionner une ou plusieurs bases",
                          label: "name",
                          "track-by": "id",
                          "preselect-first": false
                        },
                        model: {
                          value: _vm.filterForm.selectedBdv,
                          callback: function($$v) {
                            _vm.$set(_vm.filterForm, "selectedBdv", $$v)
                          },
                          expression: "filterForm.selectedBdv"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-12 col-md-3" },
                    [
                      _c("VueCtkDateTimePicker", {
                        staticStyle: {
                          display: "inline-block",
                          "margin-bottom": "20px"
                        },
                        attrs: {
                          locale: "fr",
                          range: true,
                          formatted: "ll",
                          color: "#34495e",
                          "no-label": true,
                          "custom-shortcuts": _vm.customDateRangeShortcuts,
                          "only-date": true,
                          "auto-close": false
                        },
                        model: {
                          value: _vm.filterForm.dateRange,
                          callback: function($$v) {
                            _vm.$set(_vm.filterForm, "dateRange", $$v)
                          },
                          expression: "filterForm.dateRange"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-12 col-md-2 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottom",
                              value: "Recherche",
                              expression: "'Recherche'",
                              modifiers: { hover: true, bottom: true }
                            }
                          ],
                          attrs: { variant: "primary " },
                          on: { click: _vm.getFilteredList }
                        },
                        [_c("i", { staticClass: "fas fa-search " })]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottom",
                              value: "Annuler",
                              expression: "'Annuler'",
                              modifiers: { hover: true, bottom: true }
                            }
                          ],
                          attrs: { variant: "warning ml-3" },
                          on: { click: _vm.resetFilter }
                        },
                        [_c("i", { staticClass: "fas fa-times-circle " })]
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-card-header",
                      {
                        staticClass: "p-1",
                        attrs: { "header-tag": "header", role: "tab" }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              background: "#e5e9f0",
                              color: "black !important",
                              border: "none !important"
                            },
                            attrs: { block: "" },
                            on: {
                              click: function($event) {
                                _vm.active = !_vm.active
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col text-left mt-1" }, [
                                _c("i", {
                                  staticClass: "mdi mdi-format-list-bulleted"
                                }),
                                _vm._v(" Liste des requets (Maintenance)")
                              ]),
                              _c("div", { staticClass: "col text-right" }, [
                                _vm.active
                                  ? _c("i", {
                                      staticClass:
                                        "mdi mdi-chevron-up font-size-18"
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "mdi mdi-chevron-down font-size-18"
                                    })
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-collapse",
                      {
                        attrs: {
                          id: "accordion-1",
                          visible: _vm.active,
                          accordion: "my-accordion",
                          role: "tabpanel"
                        }
                      },
                      [
                        _c("b-card-body", { staticClass: "shadow-lg" }, [
                          _c(
                            "div",
                            { staticClass: "table-responsive mb-0 shadow" },
                            [
                              _c("dataset", {
                                attrs: { "ds-data": _vm.requests },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var ds = ref.ds
                                      return [
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", {
                                            staticClass:
                                              "col-sm-12 col-md-6 mb-2 mb-md-0"
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "col pr-5" },
                                            [
                                              _c(
                                                "download-excel",
                                                {
                                                  staticClass: "float-right",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    fields: _vm.excelheader,
                                                    worksheet:
                                                      "Liste des requets (Maintenance)",
                                                    name:
                                                      "liste_des_maintenance.xls",
                                                    data: _vm.requests
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      width: "40",
                                                      src: require("@/assets/images/base/excel.png")
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-12" },
                                            [
                                              _c("div", {}, [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "table table-hover d-md-table"
                                                  },
                                                  [
                                                    _c("thead", [
                                                      _c(
                                                        "tr",
                                                        _vm._l(
                                                          _vm.cols,
                                                          function(th) {
                                                            return _c(
                                                              "th",
                                                              { key: th.field },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      th.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    ]),
                                                    _c("dataset-item", {
                                                      attrs: { tag: "tbody" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              var rowIndex =
                                                                ref.rowIndex
                                                              return [
                                                                _c(
                                                                  "tr",
                                                                  {
                                                                    class: {
                                                                      "bg-soft-info":
                                                                        _vm.selectedIndex ==
                                                                        rowIndex
                                                                    },
                                                                    staticStyle: {
                                                                      cursor:
                                                                        "pointer"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectRow(
                                                                          row,
                                                                          rowIndex
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      [
                                                                        _c(
                                                                          "router-link",
                                                                          {
                                                                            attrs: {
                                                                              to: {
                                                                                name:
                                                                                  "requests.maintenance.display",
                                                                                params: {
                                                                                  uid:
                                                                                    row.uuid
                                                                                }
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                row.ref
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.requestedBy
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.created_at_format
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c("td", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps: {
                                                                            innerHTML: _vm._s(
                                                                              row.status
                                                                            )
                                                                          }
                                                                        }
                                                                      )
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.lifebase_name
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ])
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-md-row flex-column justify-content-between align-items-center"
                                          },
                                          [
                                            _c("dataset-show", {
                                              staticClass: "mb-2",
                                              attrs: {
                                                "ds-show-entries":
                                                  _vm.defaultShow
                                              }
                                            }),
                                            _c("dataset-pager")
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("Maintenanceform", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm,
                  expression: "showForm"
                }
              ],
              attrs: { selectedRows: _vm.selectedRows, addProps: _vm.addProps },
              on: {
                realoadData: function($event) {
                  _vm.realoadData = $event
                },
                cancelBtnEven: function($event) {
                  _vm.caBTN = $event
                },
                Maintvalues: function($event) {
                  _vm.Maintenance = $event
                }
              }
            }),
            !_vm.showForm
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xl-12" }, [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.maintenanceRequestData.statusPlain ==
                                      "draft" ||
                                    _vm.maintenanceRequestData.statusPlain ==
                                      "supervisor_approved",
                                  expression:
                                    "maintenanceRequestData.statusPlain == 'draft' || maintenanceRequestData.statusPlain == 'supervisor_approved' "
                                }
                              ],
                              staticClass: "button-items float-right mr-5"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.approvalModal",
                                      modifiers: { approvalModal: true }
                                    },
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.maintenanceRequestData
                                          .statusPlain ==
                                          "supervisor_approved" &&
                                        _vm.$can("approve_mr"),
                                      expression:
                                        "maintenanceRequestData.statusPlain == 'supervisor_approved' && $can('approve_mr')"
                                    }
                                  ],
                                  attrs: { variant: "success btn-label" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-check-double font-size-16 align-middle mr-2  label-icon"
                                  }),
                                  _vm._v(" Approuver ")
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.maintenanceRequestData
                                          .statusPlain == "draft" &&
                                        _vm.$can("confirm_mr"),
                                      expression:
                                        "maintenanceRequestData.statusPlain == 'draft'  && $can('confirm_mr')"
                                    }
                                  ],
                                  attrs: { variant: "info btn-label" },
                                  on: { click: _vm.confirmRequest }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-check font-size-16 align-middle mr-2  label-icon"
                                  }),
                                  _vm._v(" Confirmer ")
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.rejectionModal",
                                      modifiers: { rejectionModal: true }
                                    },
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        (_vm.maintenanceRequestData
                                          .statusPlain == "draft" ||
                                          _vm.maintenanceRequestData
                                            .statusPlain ==
                                            "supervisor_approved") &&
                                        _vm.$can("reject_mr"),
                                      expression:
                                        "(maintenanceRequestData.statusPlain == 'draft' || maintenanceRequestData.statusPlain == 'supervisor_approved') && $can('reject_mr')"
                                    }
                                  ],
                                  attrs: { variant: "danger btn-label" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-block font-size-16 align-middle mr-2  label-icon"
                                  }),
                                  _vm._v(" Rejeter ")
                                ]
                              )
                            ],
                            1
                          ),
                          _c("h4", { staticClass: "card-title mb-4" }, [
                            _vm._v("Détails")
                          ]),
                          _c("p", { attrs: { lass: "text-muted mb-4" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.maintenanceRequestData.description) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                              _c(
                                "div",
                                { staticClass: "table-responsive mb-0" },
                                [
                                  _c("table", { staticClass: "table" }, [
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("th", [_vm._v("Demandeur :")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.maintenanceRequestData
                                                .requestedBy
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("Bloc :")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.maintenanceRequestData.bloc
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v("Lieu de maintenance :")
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.maintenanceRequestData
                                                  .locationType
                                              ) +
                                              " / " +
                                              _vm._s(
                                                _vm.maintenanceRequestData
                                                  .requestLocation
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("Base de Vie :")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.maintenanceRequestData
                                                .lifebase_name
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("Attachement :")]),
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.maintenanceRequestData
                                                    .ov_file.fullPath,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.maintenanceRequestData
                                                    .ov_file.name
                                                ) + " "
                                              ),
                                              _c("i", {
                                                staticClass: "fas fa-download"
                                              })
                                            ]
                                          )
                                        ])
                                      ]),
                                      (_vm.maintenanceRequestData.statusPlain ==
                                        "supervisor_approved" ||
                                        _vm.maintenanceRequestData
                                          .statusPlain == "approved") &&
                                      _vm.maintenanceRequestData.supervisor
                                        ? _c("tr", [
                                            _c("th", [
                                              _vm._v("Supervisé par :")
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.maintenanceRequestData
                                                    .supervisor
                                                )
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm.maintenanceRequestData.statusPlain ==
                                      "rejected"
                                        ? _c("tr", [
                                            _c("th", [_vm._v("Rejeté par :")]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.maintenanceRequestData
                                                    .rejectedBy
                                                )
                                              )
                                            ])
                                          ])
                                        : _vm._e()
                                    ])
                                  ])
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                              _c(
                                "div",
                                { staticClass: "table-responsive mb-0" },
                                [
                                  _c("table", { staticClass: "table" }, [
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v("Type de maintenance :")
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.maintenanceRequestData
                                                .requestType
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("Urgence :")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.maintenanceRequestData
                                                .requestUrgency
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("Date de demande :")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.maintenanceRequestData
                                                .requestDate_format
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("Etat :")]),
                                        _c("td", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.maintenanceRequestData
                                                  .status
                                              )
                                            }
                                          })
                                        ])
                                      ]),
                                      _vm.maintenanceRequestData.statusPlain ==
                                      "rejected"
                                        ? _c("tr", [
                                            _c("th", [
                                              _vm._v("Date de rejet:")
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.maintenanceRequestData
                                                    .rejectionDate
                                                )
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm.maintenanceRequestData.statusPlain ==
                                      "rejected"
                                        ? _c("tr", [
                                            _c("th", [
                                              _vm._v("Raison de rejet:")
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.maintenanceRequestData
                                                    .rejectionReason
                                                )
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm.maintenanceRequestData.statusPlain ==
                                      "approved"
                                        ? _c("tr", [
                                            _c("th", [
                                              _vm._v("Approuvée par :")
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.maintenanceRequestData
                                                    .approvedBy
                                                )
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm.maintenanceRequestData.statusPlain ==
                                      "approved"
                                        ? _c("tr", [
                                            _c("th", [
                                              _vm._v("Ordre de maintenance :")
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.maintenanceRequestData
                                                      .maintenanceOrder.ref
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ])
                                        : _vm._e()
                                    ])
                                  ])
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _c("approval-modal", {
              attrs: {
                modelUid: _vm.maintenanceRequestData.id,
                modelRef: _vm.maintenanceRequestData.ref,
                maintenanceContract:
                  _vm.maintenanceRequestData.maintenance_contract
              },
              on: {
                realoadData: function($event) {
                  _vm.realoadData = $event
                }
              }
            }),
            _c("rejection-modal", {
              attrs: {
                modelRef: _vm.maintenanceRequestData.ref,
                modelId: _vm.maintenanceRequestData.id,
                modelType: _vm.maintenanceRequestData.modelType
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }