

<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

import { required , requiredIf } from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
import { getDropDownElementsByTypeApi , fetchSuppliersListApi} from "@/api/common";
import Multiselect from 'vue-multiselect'
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
    import: {getDropDownElementsByTypeApi , fetchSuppliersListApi},
    page: {
        title: "Ajout Nouvelle requet",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {vueDropzone: vue2Dropzone  , Multiselect },
    props:{
      selectedRows:null,
      addProps : null,
      editProps : null
    },
    data() {
      return { 
        dropzoneOptions: {
        url: process.env.VUE_APP_BASE_URL + "system/upload/processFileUpload",
        params: {
          attachmentType: "maintenance_request",
          modalId: 1,
        },
        thumbnailWidth: 150,
        maxFilesize: 10,
        maxFiles: 1,
        maxNumberOfFiles : 1,
        // autoProcessQueue : false,
        addRemoveLinks: true,
        acceptedFiles: ".pdf,.doc,.docx,.xls,.xlsx",
      },
      invoiceAttachment: false,
      tableData: [],
      title: "Maintenance",
      types: [
        /* {
          id: "plumbing",
          title: "Plomberie",
        },

        {
          id: "electricity",
          title: "Electrique",
        },

        {
          id: "carpentry",
          title: "Menuiserie",
        },

        {
          id: "glass_work",
          title: "Travail du verre",
        },

        {
          id: "other",
          title: "Autre",
        }, */
      ],
      urgencies: [
        {
          id: "urgent",
          title: "Urgent",
        },

        {
          id: "should_be_done_soon",
          title: "Devrait être fait bientôt",
        },

        {
          id: "can_wait_vacation",
          title: "Peut attendre les vacances",
        },
      ],
      locations: [{
          id: "room",
          designation: "Chambre",
        },
        {
          id: "warehouse",
          designation: "Magasins",
        },
        {
          id: "kitchen",
          designation: "Cuisines",
        },
        {
          id: "unit",
          designation: "Unite",
        },
      ],
      
      suppliers: [],
      employeesList : [],
      supplierID : [],
      lifebases :[],
      rooms: [],
      offices: [],
      kitchens: [],
      blocLists : [],
      maintenanceReqForm: {
        lifebase_id: "",
        maintType: "",
        maintUrgency: "",
        maintLocation: [],
        bloc : [],
        
        maintUnit: "",
        maintComments: "",
        maintUuid : "",
        mo : "",
        
        requestUid: "",
        dateStart: "",
        dateEnd: "",
        timeEnd: "",
        responsibleType: [],
        responsible: [],
        comments: "",
        fileId : "",
      },
      indeterminate : false,
      
       executionResponsibleTypes: [
        {
          id: 'company',
          title: 'Direction Logistique',
        },

        {
          id: 'supplier',
          title: 'Direction Moyens Généraux'
        }
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      lastForm : null,
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Lifebase",
          href: "javascript:void(0)",
        },
        {
          text: "New Base",
          active: true,
        },
      ],
       
      // validations: {
      //           info:{
      //             date_demande_ODS: { required },
      //             date_execution_ODS: { required },
      //           }
      //       },

    };
  },
  validations: {
    maintenanceReqForm: {
      lifebase_id    : { required },
      maintType      : { required },
      maintUrgency   : { required },
      maintLocation  : { required },
      maintUnit    : { required },
      bloc  : { required },
      maintComments  : { required },
     
    },
  },
  watch:{
    addProps(val){
      if(val){
        this.clearForm();
      }
    },
    editProps(val){
      if(val){
        if(this.lastForm != null)
        this.selectedRows(this.lastForm); 
      }
    },
    async selectedRows(val){
      this.clearForm();
      if(val.length> 0 &&  undefined != val[0]){
        this.lastForm = val;
        this.maintenanceReqForm.lifebase_id   = val[0].lifebase_id;
        this.onChangeLifebase();
        this.maintenanceReqForm.maintUuid     = val[0].uuid;
        this.maintenanceReqForm.maintUrgency  = val[0].urgency_id;
        this.maintenanceReqForm.maintLocation = (this.locations.filter(x => x.id == val[0].type_location))[0];

        // setTimeout(() => {  this.maintenanceReqForm.bloc     = this.blocLists.filter(x => x.id    == parseInt(val[0].bloc_id))[0]; }, 500);
        await  new Promise(resolve => setTimeout(resolve, 400));
        this.maintenanceReqForm.bloc = val[0].bloObj;
        this.onChangeLocation(true , val);
        this.maintenanceReqForm.maintType     = val[0].type_id;
        this.maintenanceReqForm.maintComments = val[0].description;

        // if(this.maintenanceReqForm.maintLocation !== undefined && this.maintenanceReqForm.maintLocation.id == "room") this.fetchRoomsList();
        // await  new Promise(resolve => setTimeout(resolve, 400));
        // setTimeout(() => {  this.maintenanceReqForm.maintUnit     = this.rooms.filter(x => x.id    == parseInt(val[0].location_id))[0]; }, 500);
        
      }
    }
  },
  mounted(){

},
created() {
    this.getLifeBases();
    this.fetchSuppliersList();
    this.fetchMaintenanceTypeList();
    // this.fetchOfficesList();
  },
 methods: {
     async selectedRow(val){
      this.clearForm();
      if(val.length> 0 &&  undefined != val[0]){
        this.lastForm = val;
        this.maintenanceReqForm.lifebase_id   = val[0].lifebase_id;
        this.onChangeLifebase();
        this.maintenanceReqForm.maintUuid     = val[0].uuid;
        this.maintenanceReqForm.maintUrgency  = val[0].urgency_id;
        this.maintenanceReqForm.maintLocation = (this.locations.filter(x => x.id == val[0].type_location))[0];

        // setTimeout(() => {  this.maintenanceReqForm.bloc     = this.blocLists.filter(x => x.id    == parseInt(val[0].bloc_id))[0]; }, 500);
        await  new Promise(resolve => setTimeout(resolve, 400));
        this.maintenanceReqForm.bloc = val[0].bloObj;
        this.onChangeLocation(true , val);
        this.maintenanceReqForm.maintType     = val[0].type_id;
        this.maintenanceReqForm.maintComments = val[0].description;

        // if(this.maintenanceReqForm.maintLocation !== undefined && this.maintenanceReqForm.maintLocation.id == "room") this.fetchRoomsList();
        // await  new Promise(resolve => setTimeout(resolve, 400));
        setTimeout(() => {  this.maintenanceReqForm.maintUnit     = this.rooms.filter(x => x.id    == parseInt(val[0].location_id))[0]; }, 500);
        
      }
    },
    afterComplete(file) {
      var res = JSON.parse(file.xhr.response);
      this.maintenanceReqForm.fileId = res.original.uploadedFile.uuid;
    },
   cancelBtn(){
      this.$emit('cancelBtnEven',true)
      // this.cancelBtnEven = false
      this.clearForm();
      console.log(this.lastForm);
      if(this.lastForm != null)
      this.selectedRow(this.lastForm)

   },
   clearForm(){
      this.maintenanceReqForm.maintUuid       = "";
      this.maintenanceReqForm.lifebase_id     = "";
      this.maintenanceReqForm.maintType       = "";
      this.maintenanceReqForm.maintUrgency    = "";
      this.maintenanceReqForm.maintLocation   = [];
      this.maintenanceReqForm.maintComments   = "";
      this.maintenanceReqForm.mo              = false;
      this.indeterminate                      = false;  
      this.maintenanceReqForm.maintUnit       = [];
      this.maintenanceReqForm.dateStart       = "";
      this.maintenanceReqForm.dateEnd         = "";
      this.maintenanceReqForm.timeEnd         = "";
      this.maintenanceReqForm.responsibleType = [];
      this.supplierID                     = [];
      this.invoiceAttachment              = "";
      this.maintenanceReqForm.responsible = [];
      this.maintenanceReqForm.comments    = "";
      this.maintenanceReqForm.fileId      = "";
      this.$refs.myVueDropzone.removeAllFiles()
   },
   fetchSuppliersList() {
      fetchSuppliersListApi()
        .then((res) => (this.suppliers = res.data.suppliers))
        .catch(() => {})
        .finally(() => {});
    },
    fetchEmployeesList(){
      this.$http
        .post("/ops/requests/maintenance/usersList",this.supplierID)
        .then((res) => {
          this.maintenanceReqForm.responsible = "";
          this.employeesList = [];
          this.employeesList = res.data.original.data; 
          })
        .catch(() => {})
        .finally(() => {});
    },
   fetchRoomsTypeList(){
      getDropDownElementsByTypeApi('unit_type')
      .then(res => {
        this.locations = res.data.original.list
        this.locations.unshift({id: "room",type: "",uuid: "",code_liste_id: "",code_occurence: "",designation:"Chambre",systeme: 1,deleted_at: null,created_at: "",updated_at: "",arr_key: null,type_period: null})
      })
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },
   fetchMaintenanceTypeList(){
      getDropDownElementsByTypeApi('maintenance_service_type')
      .then(res => {
        this.types = res.data.original.list
      })
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },
    onChangeLifebase(){
       this.$http
        .post("/organisation/infrastructure/building/listByBase",
          {
            lifebase_id: this.maintenanceReqForm.lifebase_id
            
          })
        .then((res) =>  {
          this.blocLists = []
          this.blocLists = res.data.original.list})
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
   onChangeLocation(set = false ,val = null){
     var location = this.maintenanceReqForm.maintLocation.id
      switch (location) {
        case "room":
          this.fetchRoomsList(set,val);
          break;
        case "warehouse":
          this.fetchWarehousesList(set,val);
          break;
        case "kitchen":
          this.fetchKitchensList(set,val);
          break;
        case "unit":
          this.fetchUnitsViaType(set,val);
          break;
      }
   },
    getLifeBases(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http.get('/base/lifebases/index')
      .then(response => {
          this.lifebases = response.data;
          this.$emit('lifebaseList',this.lifebases);
          loader.hide();
      })
      .catch(error => console.log(error))
    },
    fetchRoomsList(set = false ,val = null) {
      this.$http
        .post("/infrastructure/rooms/listByBase",
          {
            lifebase_id: this.maintenanceReqForm.lifebase_id,
            bloc_id: this.maintenanceReqForm.bloc.id
          })
        .then((res) =>  {
          this.rooms = []
          this.rooms = res.data.original.list
          if(set){
            this.maintenanceReqForm.maintUnit     = this.rooms.filter(x => x.id    == parseInt(val[0].location_id))[0];
          }  
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchWarehousesList(set = false ,val = null) {
      this.$http
        .post("/system/stock/warehouses/getWarehouseListViaBase",
          {
            lifebase_id: this.maintenanceReqForm.lifebase_id,
            bloc_id: this.maintenanceReqForm.bloc.id
          })
        .then((res) => {
          this.rooms = []
          this.rooms = res.data.original.list
          if(set){
            this.maintenanceReqForm.maintUnit     = this.rooms.filter(x => x.id    == parseInt(val[0].location_id))[0];
          }  
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchKitchensList(set = false ,val = null) {
      this.$http
        .post("/infrastructure/kitchens/getKitchenListViaBase",
          {
            lifebase_id: this.maintenanceReqForm.lifebase_id,
            bloc_id: this.maintenanceReqForm.bloc.id
          })
        .then((res) =>  {
          this.rooms = []
          this.rooms = res.data.original.list
          if(set){
            this.maintenanceReqForm.maintUnit     = this.rooms.filter(x => x.id    == parseInt(val[0].location_id))[0];
          }  
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchUnitsList(set = false ,val = null) {
      this.$http
        .post("/infrastructure/rooms/listByBase",
          {
            lifebase_id: this.maintenanceReqForm.lifebase_id,
            bloc_id: this.maintenanceReqForm.bloc.code
          })
        .then((res) =>  {
          this.rooms = []
          this.rooms = res.data.original.list
          if(set){
            this.maintenanceReqForm.maintUnit     = this.rooms.filter(x => x.id    == parseInt(val[0].location_id))[0];
          }  
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    fetchUnitsViaType() {
      this.$http
        .post("/organisation/infrastructure/unit/getUnitListViaBase",{
          lifebase_id :this.maintenanceReqForm.lifebase_id,
            bloc_id: this.maintenanceReqForm.bloc.id
          })
        .then((res) =>  {
          this.rooms = []
          this.rooms = res.data.original.list})
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    formSubmit() {
     
     this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitted = false
        let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
        this.$http
          .post("/ops/requests/maintenance/store", this.maintenanceReqForm)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.$emit('realoadData',true)
                this.clearForm();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
             loader.hide();
          })
          .catch((error) => {
             loader.hide();
            Swal.fire("Erreur!", error.message, "error");
          })
          .finally(() => {});
      }
    },
  },
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
       <div class="card">
          <div class="card-body">
            <form class="needs-validation"  enctype="multipart/form-data">
              <h4 class="card-title  pb-2 mb-10">
                    Details 
              </h4>
              <div class="row mr-0 pl-0 mt-2">
                <div class="form-group col-xs-12 col-md-4 col-lg">
                  <label for="formrow-inputCity">Base de Vie *</label>
                    <select  :class="{'is-invalid': submitted && $v.maintenanceReqForm.lifebase_id.$error,}"  @change="onChangeLifebase" class="form-control" v-model="maintenanceReqForm.lifebase_id" >
                        <option v-for="type in lifebases" :key="type.id" :value="type.id"> {{type.name}} </option>
                    </select>
                     <div
                      v-if="
                        submitted && $v.maintenanceReqForm.lifebase_id.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.maintenanceReqForm.lifebase_id.required"
                        >le champs est obligatoire.</span
                      >
                    </div>
                </div>
                <div class="form-group col-xs-12 col-md-4 col-lg">
                  <label for="formrow-inputCity">Type de maintenance *</label>
                    <select  :class="{'is-invalid': submitted && $v.maintenanceReqForm.maintType.$error,}"  class="form-control" v-model="maintenanceReqForm.maintType">
                        <option v-for="type in types" :key="type.id" :value="type.id"> {{type.designation}} </option>
                    </select>
                     <div
                      v-if="
                        submitted && $v.maintenanceReqForm.maintType.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.maintenanceReqForm.maintType.required"
                        >le champs est obligatoire.</span
                      >
                    </div>
                </div>

                <div class="form-group col-xs-12 col-md-4 col-lg">
                    <label for="formrow-password-input">Urgence *</label>
                    <select  :class="{'is-invalid': submitted && $v.maintenanceReqForm.maintUrgency.$error,}"  class="form-control" v-model="maintenanceReqForm.maintUrgency">
                          <option v-for="urgencie in urgencies" :key="urgencie.id" :value="urgencie.id"> {{urgencie.title}} </option>
                      </select>
                    <div
                      v-if="
                        submitted && $v.maintenanceReqForm.maintUrgency.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.maintenanceReqForm.maintUrgency.required"
                        >le champs est obligatoire.</span
                      >
                    </div>
                </div>
                <div class="form-group col-xs-12 col-md-4 col-lg">
                  <label for="formrow-inputCity">Lieu de maintenance *</label>
                  
                    <multiselect   
                      :close-on-select="true"
                      :select-label="''"
                      :deselect-label="''" 
                      :class="{'is-invalid': submitted && $v.maintenanceReqForm.maintLocation.$error,}"   
                      v-model="maintenanceReqForm.maintLocation"  :searchable="true" 
                      track-by="id" label="designation" :options="locations" placeholder="Select a room" :allow-empty="false">
                    <template slot="singleRoom" slot-scope="{ location }">{{ location.designation }}</template>
                  </multiselect>
                  <div
                      v-if="submitted && $v.maintenanceReqForm.maintLocation.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.maintenanceReqForm.maintLocation.required"
                        >le champs est obligatoire.</span
                      >
                    </div>
                </div>
                <div class="form-group col-xs-12 col-md-4 col-lg">
                  <label for="formrow-inputCity">Bloc *</label>
                    <multiselect   
                      :close-on-select="true"
                      :select-label="''"
                      :deselect-label="''" 
                      :class="{'is-invalid': submitted && $v.maintenanceReqForm.bloc.$error,}"   
                      @input="onChangeLocation" v-model="maintenanceReqForm.bloc"  :searchable="true" 
                      track-by="id" label="name" :options="blocLists" placeholder="Select a bloc" :allow-empty="false">
                    <template slot="singleBloc" slot-scope="{ location }">{{ location.name }}</template>
                  </multiselect>
                  <div
                      v-if="submitted && $v.maintenanceReqForm.bloc.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.maintenanceReqForm.bloc.required"
                        >le champs est obligatoire.</span
                      >
                    </div>
                </div>
                <div class="form-group col-xs-12 col-md-4 col-lg" v-if="maintenanceReqForm.maintLocation !== undefined && maintenanceReqForm.maintLocation.designation">
                    <label for="formrow-password-input">{{maintenanceReqForm.maintLocation.designation}} *</label>
                    <multiselect 
                      :close-on-select="true"
                      :select-label="''"
                      :deselect-label="''" 
                      :class="{'is-invalid': submitted && $v.maintenanceReqForm.maintUnit.$error,}"  
                      v-model="maintenanceReqForm.maintUnit" v-if="maintenanceReqForm.maintLocation.id == 'room'" 
                      :searchable="true" track-by="id" label="number" :options="rooms" placeholder="Select an office" :allow-empty="false">
                      <template slot="singleOffice" slot-scope="{ room }">{{ room.number }}</template>
                    </multiselect>
                    <multiselect 
                      :close-on-select="true"
                      :select-label="''"
                      :deselect-label="''" 
                      :class="{'is-invalid': submitted && $v.maintenanceReqForm.maintUnit.$error,}"  
                      v-model="maintenanceReqForm.maintUnit" v-else :searchable="true" track-by="id" label="name" 
                      :options="rooms" placeholder="Select an office" :allow-empty="false">
                      <template slot="singleOffice" slot-scope="{ room }">{{ room.name }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.maintenanceReqForm.maintUnit.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.maintenanceReqForm.maintUnit.required"
                        >le champs est obligatoire.</span
                      >
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-10">
                    <label for="formrow-inputCity">Description *</label>
                    <textarea :class="{'is-invalid': submitted && $v.maintenanceReqForm.maintComments.$error,}"
                      v-model="maintenanceReqForm.maintComments"
                      class="form-control"
                      cols="30"
                      rows="2"
                    ></textarea>
                </div>
                <div class="col-xs-12 col-md-2 col-2">
                  <label for="">Attachement :</label>
                  <vue-dropzone
                    id="dropzone"
                    v-model="invoiceAttachment"
                    class="ebs-upload"
                    ref="myVueDropzone"
                    :use-custom-slot="true"
                    :options="dropzoneOptions"
                    @vdropzone-complete="afterComplete"
                  >
                    <div class="dropzone-custom-content">
                      <i class="display-4 text-muted fas fa-upload"></i>
                    </div>
                  </vue-dropzone>
                </div>
              </div>
              <div class="col" v-if="$can('create_maintenance_order')">
                <b-form-checkbox
                    :indeterminate.sync="indeterminate"
                    v-model="maintenanceReqForm.mo"
                    switch  
                    class="mb-3"
                    >Ordre de Travail</b-form-checkbox
                  >
              </div>
              <div v-if="maintenanceReqForm.mo">
                     <div class="row">
                      <div class="col-md-4">
                        <label for="formrow-inputCity">Date début d'intervnetion *</label>
                        <input
                          type="date"
                          v-model="maintenanceReqForm.dateStart"
                          class="form-control"
                          name=""
                          id=""
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="formrow-inputCity">Date fin d'intervnetion *</label>
                        <input
                          type="date"
                          v-model="maintenanceReqForm.dateEnd"
                          class="form-control"
                          name=""
                          id=""
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="formrow-inputCity">Heure fin d'intervnetion *</label>
                        <input
                          type="time"
                          v-model="maintenanceReqForm.timeEnd"
                          class="form-control"
                          name=""
                          id=""
                        />
                      </div>
                    </div> <br/>
                    <div class="row">
                      <div class="col-xs-12 col-md col-lg">
                        <label for="formrow-inputCity">Responsable d'exécution *</label>
                        <multiselect v-model="maintenanceReqForm.responsibleType"
                          :close-on-select="true"
                          :select-label="''"
                          :deselect-label="''"  
                          :searchable="true" track-by="id" label="title" :options="executionResponsibleTypes" placeholder="Select responsible type" :allow-empty="false">
                          <template slot="singleexecutionResponsibleType" slot-scope="{ executionResponsibleType }">{{ executionResponsibleType.title }}</template>
                        </multiselect>
                      </div>
                      <div class="col-xs-12 col-md col-lg" v-if="maintenanceReqForm.responsibleType.id == 'supplier'">
                        <label for="formrow-email-input">Prestataire *</label>
                          <multiselect
                            :close-on-select="true"
                            :select-label="''"
                            :deselect-label="''" 
                            @close="fetchEmployeesList()"
                            v-model="supplierID"
                            :searchable="true"
                            track-by="id"
                            label="name"
                            :options="suppliers"
                            placeholder="Select Supplier"
                            :allow-empty="false"
                          >
                            <template
                              slot="singleSupplier"
                              slot-scope="{ supplier }"
                              >{{ supplier.name }}</template
                            >
                          </multiselect>
                      </div>
                      <div class="col-xs-12 col-md-4 col-lg"  v-if="maintenanceReqForm.responsibleType.id == 'supplier'">
                        <div class="form-group">
                          <label for="formrow-email-input">Employees *</label>
                          <multiselect
                            v-model="maintenanceReqForm.responsible"
                            :searchable="true"
                            track-by="id"
                            label="fullName"
                            :close-on-select="true"
                            :select-label="''"
                            :deselect-label="''" 
                            :multiple="false"
                            :options="employeesList"
                            :allow-empty="false"
                          >
                            <template slot="singleLabel" slot-scope="{ option }">
                              {{option.fullName }}
                            </template>
                          </multiselect>
                          <div
                            v-if="submitted && $v.maintenanceReqForm.responsible.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.maintenanceReqForm.responsible.required"
                              >Le champ est obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div> <br>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="formrow-inputCity">Commentaires *</label>
                          <textarea
                            v-model="maintenanceReqForm.comments"
                            class="form-control"
                            cols="30"
                            rows="4"
                          ></textarea>
                        </div>
                      </div>
                  </div>
              </div>
              
              <div class="row">
                <div class="col-md-12 "> 
                  <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2 mr-3">
                    <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                  </button>
                  
                  <button type="button" @click="formSubmit"  class="btn btn-label btn-primary float-right" >
                    <i class="far fa-save label-icon "></i> Enregistrer
                  </button>
                  
                </div>
              </div>  
                  <!-- <button type="button" @click="formSubmit" class="btn btn-label btn-success float-right "><i class="fas fa-check  label-icon"></i> Valider </button> -->
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
<style >
  .dropzone {
    min-height: 5px;
    padding: 0px;
  }
</style>
