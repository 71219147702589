<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Maintenanceform from "./components/Maintenanceform";
import rejectionModal from "../../common/modals/rejectionModal";
import approvalModal from "./components/approveRequestModal";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
// import DisplayCmp from "./components/DisplayCmp";
import Multiselect from "vue-multiselect";
import { mapGetters } from 'vuex';


export default {
  page: {
    title: "Requêtes de maintenance",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Multiselect , Layout, PageHeader , Maintenanceform, rejectionModal, approvalModal /* ,DisplayCmp */,
  Dataset, DatasetItem,DatasetPager /* DatasetInfo,,  DatasetSearch,*/ ,DatasetShow },
  data() {
    return {
      searchDisable : false,
      search: '',
      defaultShow: 5,
       maintenanceRequestData: {
        id: 0,
        uuid : "",
        ref: "",
        description: "",
        requestedBy: "",
        requestDate: "",
        requestType: "",
        requestLocationType: "",
        requestLocation: "",
        requestUrgency: "",
        status: "",
        statusPlain: "",
        maintenanceOrderRef: "",
        maintenanceOrder: [],
        modelType: "",
        rejectedBy: "",
        approvedBy: "",
        supervisor: "",
        rejectionReason: "",
        fileId: "",
        rejectionDate: "",
        bloc: "",
        ov_file:[],
        maintenance_contract:'',
      },
      tableData: [],
      showForm: false,
      title: "Maintenance",
      List_maintenance: [],
      requests : [],
      rows:'',
      active:false,
      selectedRows:[],
      addProps : false,
      items: [
        {
          text: "Catalogue des Services",
          to: {name:"service_catalog"},
        },
        {
          text: "Requêtes",
          to: {name:"requests"},
        },
        {
          text: "Requêtes de maintenance",
          active: true,
        },
      ],
      cols: [
         {
          name: "Référence ",
          field: "ref",
          sort: ''
        },
        {
          name: "Demandé par",
          field: "requestedBy",
          sort: ''
        },
        {
          name: "Date de requête",
          field: "created_at",
          sort: ''
        },
        {
          name: "Status",
          field: "status",
          sort: ''
        },
        {
          name: "Base de Vie",
          field: "lifebase_name",
          sort: ''
        },
      ],
      excelheader:
         {
          "Référence "          : "ref",
          "Demandé par"         : "requestedBy",
          "Type de maintenance ": "type",
          "Base de Vie"         : "lifebase_name",
          "Bloc "               : "bloc",
          "Lieu de maintenance" : "locationRec",
          "Date de requête"     : "created_at",
          "Status"              : "status",
        },
      selectedIndex:-1,

      addRM      : false,
      cancelRM   : false,
      duplicateRM: true,
      deleteRM   : true,
      editRM     : true,
      lastSelect : null,
      caBTN : false,
      locations: [{
          id: "room",
          designation: "Chambre",
        },
        {
          id: "warehouse",
          designation: "Magasins",
        },
        {
          id: "kitchen",
          designation: "Cuisines",
        },
        {
          id: "unit",
          designation: "Unite",
        },
        {
          id: "restaurant",
          designation: "Restaurant",
        },
      ],
      realoadData : false,
      lifebases : [],
      lifebase_id : '',
      drafts : [],
      pendings : [],
      confirms : [],
      valids : [],
      rejects : [],
      requestsFull : [],
      filterForm : {
        selectedBdv : [],
        etat : '',
        dateRange :''
      },
      etat : [],
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
    }
  },
  mounted(){
    this.getLifebaseList();
    //  this.getListPaginate();
     this.getList();
  },
   computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    })
  },
  watch:{
    realoadData(val){
      if(val){
        this.refreshList();
      }
    },
    caBTN(val){
      if(val)
       this.cancelBtn();
    },
    selectedRows(val){
      this.clearContent();
      this.addRM       = false;
      this.cancelRM    = true;
      this.showForm    = false;
      if(val.length> 0 &&  undefined != val[0]){
        this.deleteRM      = false;
        this.editRM      = false;
        this.lastSelect = val[0];

        this.setSelectedRow(val[0])
      }
    },
    Maintenance:{
        handler(val){
          this.refreshList();
          this.List_maintenance.push({
              //ID_ODS:val.ID_ODS,
              red:val.ref,
              requestedBy:val.requestedBy,
              created_at:val.created_at,
              status:val.status,
          }); 
        }
    }
  },
  methods:{
    confirmRequest(){
      var contUid = this.maintenanceRequestData.uuid
      var contRef = this.maintenanceRequestData.ref
      var _this = this;
      if(contUid == "")
        this.$toast.error("Aucun élément n'est étais sélection");
      else if(!(this.maintenanceRequestData.statusPlain == 'draft'))
        this.$toast.error("Vous ne pouvez pas confirmé, car la demande est approuvée");
      else
      Swal.fire({
        title: "Êtes-vous sûr de Confirmé " + contRef + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#50a5f1",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/requests/maintenance/confirme/" + contUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  // Swal.fire("Félicitations!", res.data.original.msg, "success");
                  this.refreshList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  // Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    ListRequests(data){
      if(this.etat == data)
      this.resetFilter()
      else{
        this.etat            = data;
        this.filterForm.etat = data;
        if(data == 'draft') this.requests = this.drafts;
        else if(data == 'supervisor_approved') this.requests = this.pendings;
        else if(data == 'supervisor_approved') this.requests = this.confirms;
        else if(data == 'approved') this.requests = this.valids;
        else if(data == 'rejected') this.requests = this.rejects;
      }
      if(!this.active) this.active = !this.active
    },
    getFilteredList(){
      let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
      })
      this.$http
        .post("/ops/requests/maintenance/filterdList",  {from: this.filterForm.dateRange.start,
          to: this.filterForm.dateRange.end, etat: this.filterForm.etat , selectedBdv: this.filterForm.selectedBdv})
        .then((res) => {
          this.requests     = res.data.original.list;
          this.filterData();
          loader.hide();
        })
        .catch(() => {
          loader.hide();

          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    filterData(){
      this.drafts       = this.requests.filter(x => (x.statusPlain == 'draft'));
      this.confirms     = this.requests.filter(x => (x.statusPlain == 'supervisor_approved'));
      this.valids       = this.requests.filter(x => (x.statusPlain == 'approved'));
      this.rejects      = this.requests.filter(x => (x.statusPlain == 'rejected'));
    },
    resetFilter(){
      this.filterForm.selectedBdv = [];
      this.filterForm.dateRange   = [];
      this.filterForm.etat        = "";
      this.requests               = this.requestsFull;
      this.etat                   = "";
      this.filterData();
    },
    getLifebaseList(){
      this.$http.get('/base/lifebases/index')
      .then(response => {
          this.lifebases = response.data;
      })
      .catch(error => console.log(error))
    },
    afterComplete(file) {
      var res = JSON.parse(file.xhr.response);
      this.takewayMealRequestForm.fileId = res.original.uploadedFile.uuid;
    },
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
    clearContent(){
      this.maintenanceRequestData.id                  = 0;
      this.maintenanceRequestData.uuid                 = "";
      this.maintenanceRequestData.ref                 = "";
      this.maintenanceRequestData.description         = "";
      this.maintenanceRequestData.requestedBy         = "";
      this.maintenanceRequestData.requestDate         = "";
      this.maintenanceRequestData.requestType         = "";
      this.maintenanceRequestData.requestLocationType = "";
      this.maintenanceRequestData.requestLocation     = "";
      this.maintenanceRequestData.requestUrgency      = "";
      this.maintenanceRequestData.status              = "";
      this.maintenanceRequestData.statusPlain         = "";
      this.maintenanceRequestData.maintenanceOrder    = "";
      this.maintenanceRequestData.modelType           = "";
      this.maintenanceRequestData.rejectedBy          = "";
      this.maintenanceRequestData.rejectionReason     = "";
      this.maintenanceRequestData.rejectionDate       = "";
      this.maintenanceRequestData.locationType        = "";
      this.maintenanceRequestData.lifebase_name       = "";
      this.maintenanceRequestData.bloc                = "";
      this.maintenanceRequestData.ov_file             = [];
      this.maintenanceRequestData.supervisor          = "";
      this.maintenanceRequestData.approvedBy          = "";
      this.maintenanceRequestData.maintenance_contract          = null;

   },
    setSelectedRow(sel){
      this.lastSelect = sel
      this.maintenanceRequestData.id                  = sel.id;
      this.maintenanceRequestData.uuid                = sel.uuid;
      this.maintenanceRequestData.ref                 = sel.ref;
      this.maintenanceRequestData.description         = sel.description;
      this.maintenanceRequestData.requestedBy         = sel.requestedBy;
      this.maintenanceRequestData.requestDate_format  = sel.created_at_format;
      this.maintenanceRequestData.requestType         = sel.type;
      this.maintenanceRequestData.requestLocationType = sel.location;
      this.maintenanceRequestData.requestLocation     = sel.locationRec;
      this.maintenanceRequestData.requestUrgency      = sel.urgency;
      this.maintenanceRequestData.status              = sel.status;
      this.maintenanceRequestData.statusPlain         = sel.statusPlain;
      this.maintenanceRequestData.maintenanceOrder    = sel.maintenanceOrder;
      this.maintenanceRequestData.modelType           = sel.modelType;
      this.maintenanceRequestData.rejectedBy          = sel.rejectedBy;
      this.maintenanceRequestData.rejectionReason     = sel.rejectionReason;
      this.maintenanceRequestData.rejectionDate_format= sel.rejectionDate_format;
      this.maintenanceRequestData.supervisor          = sel.supervisor;
      this.maintenanceRequestData.approvedBy          = sel.approvedBy;
      this.maintenanceRequestData.lifebase_name       = sel.lifebase_name;
      this.maintenanceRequestData.ov_file             = sel.ov_file;
      this.maintenanceRequestData.bloc                = sel.bloc;
      this.maintenanceRequestData.maintenance_contract = sel.maintenance_contract;
      if(sel.type_location)
      this.maintenanceRequestData.locationType        = (this.locations.filter(x => x.id    == sel.type_location))[0].designation;

    }
    ,
    addBtn(){
      this.addProps    = true;
      this.addRM       = false;
      this.cancelRM    = false;
      this.duplicateRM = true;
      this.editRM      = true;
      this.showForm    = true;
      // this.info = this.restInfo;
    },
    duplicateBtn(){
      this.addRM       = true;
      this.cancelRM    = false;
      this.duplicateRM = false;
      this.editRM      = true;
      this.showForm    = true;
      // this.selectedRows.push( this.selectedRows[0]);
      // this.info.uuid = "";
    },
    editBtn(){
      this.addRM    = true;
      this.editRM   = false;
      this.deleteRM = true;
      this.showForm = true;
    },
    cancelBtn(){
      this.addRM         = false;
      this.cancelRM      = true;
      this.duplicateRM   = false;
      this.editRM        = true;
      this.caBTN         = false;
      this.deleteRM      = true;
      this.showForm      = false;
      // this.caBTN = false;
      // this.maintenanceRequestData = this.lastSelect;
      if(this.lastSelect != null){
        this.setSelectedRow(this.lastSelect);
        this.editRM   = false;
        this.deleteRM = false;
      }
    },
    searchUnit (){
        if(this.search.length > 3)
        this.getListPaginate();
        else if(this.search.length == 0)
        this.getListPaginate();
    },
    onShowChange(){
      this.getListPaginate();
    },
    getListPaginate(page = 1){
      let loader = this.$loading.show();
      var show = this.defaultShow;
      this.searchDisable = true;
      this.$http.post('/ops/requests/maintenance/listPagenate?page=' + page+'&show=' + show +'&q='+this.search+'&lifebase_id='+this.lifebase_id)
      .then(response => {
        this.selectedRows  = []; this.selectedIndex = -1;
        this.clearContent();
        this.requests      = response.data.original.list;
        this.requestsFull  = this.requests;
        this.realoadData   = false;
        this.showForm      = false;
        loader.hide();
        this.searchDisable = false;
          // this.List_maintenance = response.data;
          // this.selectedRows.push(this.requests[0]);
      })
      .catch(error => {
        console.log(error)
        loader.hide();})
    },
    getList(){
      let types = [];
      if(this.$can('restaurants_maintenance')) types.push('restaurant');
      if(this.$can('kitchen_maintenance')) types.push('kitchen');
      if(this.$can('warhouse_maintenance')) types.push('warhouse');
      if(this.$can('room_maintenance')) types.push('room');

      this.$http.post('/ops/requests/maintenance/list',{types:types})
      .then(response => {
        this.selectedRows = []; this.selectedIndex = -1;
        this.clearContent();
        this.requests = response.data.original.list;
        this.requestsFull = this.requests;
        this.drafts       = this.requests.filter(x => (x.statusPlain == 'draft'));
        this.pendings     = this.requests.filter(x => (x.statusPlain == 'supervisor_approved'));
        this.confirms     = this.requests.filter(x => (x.statusPlain == 'supervisor_approved'));
        this.valids       = this.requests.filter(x => (x.statusPlain == 'approved'));
        this.rejects      = this.requests.filter(x => (x.statusPlain == 'rejected'));
        this.realoadData = false;
        this.showForm = false;
          // this.List_maintenance = response.data;
          // this.selectedRows.push(this.requests[0]);
      })
      .catch(error => console.log(error))
    },
    refreshList(){
      this.selectedRows=[];this.selectedIndex=-1;
      this.realoadData=false;
      this.getList();
    },
    deleteR() {
      var contUid = this.maintenanceRequestData.uuid
      var contRef = this.maintenanceRequestData.ref
      var _this = this;
      if(contUid == "")
        this.$toast.error("Aucun élément n'est étais sélection");
      else if(!(this.maintenanceRequestData.statusPlain == 'draft'))
        this.$toast.error("Vous ne pouvez pas supprimer, car la demande est approuvée");
      else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + contRef + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/requests/maintenance/delete/" + contUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  // Swal.fire("Félicitations!", res.data.original.msg, "success");
                  this.refreshList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  // Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  }
  
};
</script>
<style scoped>
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #Sticky {
    position: fixed;
    right: 0;
    top: 50%;
    width: 4em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
/* 
  #Sticky i {
      font-size: ;
  } */
  .rounded-pill{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50%;
  }
  .btn-cash{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #50A5F1 ;
  }
  .btn-add{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #002A59 ;
  }
  .btn-duplicate{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-confirm{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-reject{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: red ;
  }
  .btn-validate{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #00708F ;
  }
  .btn-edit{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color:  #FFB236 ;
  }
  .btn-delete{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-reset{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #9a0c26;
  }
  .btn-upload{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #6B8B9C ;
  }
  .btn-download{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #A6C1CF;
  }
  .btn-print{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <ul id="Sticky">
        <li>
          <button type="button" data-toggle="tooltip" data-placement="left" title="Ajouter" :disabled="addRM" @click="addBtn" class="btn btn-add rounded-pill float-right mr-1 mt-1">
          <i class="fas fa-plus text-white"></i>
          </button>
        </li>
        <li>
          <button type="button" data-toggle="tooltip" data-placement="left"  title="Modifier" :disabled="editRM || (maintenanceRequestData.statusPlain != 'draft' && maintenanceRequestData.statusPlain != 'supervisor_approved') " @click="editBtn" class="btn btn-edit rounded-pill float-right mr-1 mt-1">
          <i class="fas fa-pencil-alt text-white"></i>
          </button>
        </li>
        <li>
          <button type="button" data-toggle="tooltip" data-placement="left" title="supprimer" :disabled="deleteRM || (maintenanceRequestData.statusPlain != 'draft' && maintenanceRequestData.statusPlain != 'supervisor_approved') " @click="deleteR()" class="btn btn-delete rounded-pill float-right mr-1 mt-1">
          <i class="far fa-trash-alt text-white"></i>
          </button>
        </li>
      </ul>
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="btn-group col-12">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'draft'}"
                  @click="ListRequests('draft')"
                >
                  <i class="fas fa-file-signature"></i> <span class="badge bg-secondary text-white">{{ drafts.length }}</span> Brouillon
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'supervisor_approved'}"
                  @click="ListRequests('supervisor_approved')"
                >
                  <i class="fas fa-check text-info"></i> <span class="badge bg-info text-white">{{ confirms.length }}</span> Confirmé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'approved'}"
                  @click="ListRequests('approved')"
                >
                  <i class="fas fa-check-double text-success"></i>
                  <span class="badge bg-success text-white ml-1"> {{ valids.length }}</span> Validé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'rejected'}"
                  @click="ListRequests('rejected')"
                >
                  <i class="fas fa-ban text-danger"></i>
                  <span class="badge bg-danger text-white ml-1"> {{ rejects.length }}</span> Rejeté
                </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-3"> 
              <Multiselect
                v-model="filterForm.selectedBdv"
                :options="user.lifebase"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :select-label="''"
                :deselect-label="''"
                placeholder="Selectionner une ou plusieurs bases"
                label="name"
                track-by="id"
                :preselect-first="false"
              />
          </div>
          <div class="col-sm-12 col-md-3">
              <VueCtkDateTimePicker
                locale="fr"
                v-model="filterForm.dateRange"
                style="display:inline-block;margin-bottom: 20px;"
                :range="true"
                formatted="ll"
                color="#34495e"
                :no-label="true"
                :custom-shortcuts="customDateRangeShortcuts"
                :only-date="true"
                :auto-close="false"
              ></VueCtkDateTimePicker>
          </div>
          <div class="col-sm-12 col-md-2 text-right">
            <b-button  v-b-tooltip.hover.bottom="'Recherche'" variant="primary " @click="getFilteredList"> <i class="fas fa-search " ></i><!-- Rechercher --></b-button>
            <b-button  v-b-tooltip.hover.bottom="'Annuler'" variant="warning ml-3"  @click="resetFilter"> <i class="fas fa-times-circle "></i> <!-- Réinitialiser --></b-button>
          </div>  
        </div>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des requets (Maintenance)</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
               
                <dataset v-slot="{ ds }" :ds-data="requests" >
                  <div class="row">
                    <div class="col-sm-12 col-md-6 mb-2 mb-md-0">
                    </div>
                    <div class="col pr-5">
                      <download-excel 
                      :fields="excelheader"
                      style="cursor:pointer" 
                      class="float-right" 
                      worksheet="Liste des requets (Maintenance)"
                      name="liste_des_maintenance.xls"
                      :data="requests">
                        <img width="40" :src="require('@/assets/images/base/excel.png')" />
                      </download-excel>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>
                                <router-link
                                  :to="{
                                    name: 'requests.maintenance.display',
                                    params: { uid: row.uuid },
                                  }"
                                  >{{ row.ref }}</router-link
                                >
                              </td>
                              <td>{{ row.requestedBy }}</td>
                              <td>{{ row.created_at_format }}</td>
                              <td><span v-html="row.status"></span></td>
                              <td>{{ row.lifebase_name }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-show class="mb-2" :ds-show-entries="defaultShow"/>
                    <dataset-pager />
                  </div>
                </dataset>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      
          <Maintenanceform :selectedRows="selectedRows"  :addProps="addProps" @realoadData="realoadData = $event" @cancelBtnEven="caBTN = $event" @Maintvalues="Maintenance = $event" v-show="showForm"  />
          <div   v-if="!showForm" >
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div
                    class="button-items float-right mr-5"
                    v-show="maintenanceRequestData.statusPlain == 'draft' || maintenanceRequestData.statusPlain == 'supervisor_approved' "
                  >
                    <b-button
                      variant="success btn-label"
                      v-b-modal.approvalModal
                      v-show="maintenanceRequestData.statusPlain == 'supervisor_approved' && $can('approve_mr')"
                    >
                      <i
                        class="bx bx-check-double font-size-16 align-middle mr-2  label-icon"
                      ></i>
                      Approuver
                    </b-button>
                    <b-button
                      variant="info btn-label"
                      @click="confirmRequest"
                      v-show="maintenanceRequestData.statusPlain == 'draft'  && $can('confirm_mr')"
                    >
                      <i
                        class="bx bx-check font-size-16 align-middle mr-2  label-icon"
                      ></i>
                      Confirmer
                    </b-button>

                    <b-button
                      variant="danger btn-label"
                      v-b-modal.rejectionModal
                      v-show="(maintenanceRequestData.statusPlain == 'draft' || maintenanceRequestData.statusPlain == 'supervisor_approved') && $can('reject_mr')"
                    >
                      <i class="bx bx-block font-size-16 align-middle mr-2  label-icon"></i>
                      Rejeter
                    </b-button>
                  </div>
                    <h4 class="card-title mb-4">Détails</h4>

                    <p lass="text-muted mb-4">
                      {{ maintenanceRequestData.description }}
                    </p>
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="table-responsive mb-0">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th>Demandeur :</th>
                                <td>{{ maintenanceRequestData.requestedBy }}</td>
                              </tr>
                              <tr>
                                <th>Bloc :</th>
                                <td>{{ maintenanceRequestData.bloc }}</td>
                              </tr>
                              <tr>
                                <th>Lieu de maintenance :</th>
                                <td>
                                  {{ maintenanceRequestData.locationType }} /
                                  {{ maintenanceRequestData.requestLocation }}
                                </td>
                              </tr>
                              <tr>
                                <th>Base de Vie :</th>
                                <td>{{ maintenanceRequestData.lifebase_name }}</td>
                              </tr>         
                              <tr>
                                <th>Attachement :</th>
                                <td><a :href="maintenanceRequestData.ov_file.fullPath" target="_blank">{{maintenanceRequestData.ov_file.name}} <i class="fas fa-download"></i></a></td>
                              </tr>
                              
                              <tr
                                v-if="(maintenanceRequestData.statusPlain == 'supervisor_approved' || maintenanceRequestData.statusPlain == 'approved') && maintenanceRequestData.supervisor "
                              >
                                <th>Supervisé par  :</th>
                                <td>{{ maintenanceRequestData.supervisor }}</td>
                              </tr>
                              <tr
                                v-if="maintenanceRequestData.statusPlain == 'rejected'"
                              >
                                <th>Rejeté par :</th>
                                <td>{{ maintenanceRequestData.rejectedBy }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="table-responsive mb-0">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th>Type de maintenance :</th>
                                <td>{{ maintenanceRequestData.requestType }}</td>
                              </tr>
                              <tr>
                                <th>Urgence :</th>
                                <td>{{ maintenanceRequestData.requestUrgency }}</td>
                              </tr>
                              <tr>
                                <th>Date de demande :</th>
                                <td>{{ maintenanceRequestData.requestDate_format }}</td>
                              </tr>
                              <tr>
                                <th>Etat :</th>
                                <td>
                                  <span v-html="maintenanceRequestData.status"></span>
                                </td>
                              </tr>
                              <tr
                                v-if="maintenanceRequestData.statusPlain == 'rejected'"
                              >
                                <th>Date de rejet:</th>
                                <td>{{ maintenanceRequestData.rejectionDate }}</td>
                              </tr>
                              <tr
                                v-if="maintenanceRequestData.statusPlain == 'rejected'"
                              >
                                <th>Raison de rejet:</th>
                                <td>{{ maintenanceRequestData.rejectionReason }}</td>
                              </tr>
                               <tr
                                v-if="maintenanceRequestData.statusPlain == 'approved'"
                              >
                                <th>Approuvée par :</th>
                                <td>{{ maintenanceRequestData.approvedBy }}</td>
                              </tr>

                              <tr
                                v-if="maintenanceRequestData.statusPlain == 'approved'"
                              >
                                <th>Ordre de maintenance :</th>
                                <td>
                                  {{ maintenanceRequestData.maintenanceOrder.ref }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <approval-modal
        :modelUid="maintenanceRequestData.id"
        :modelRef="maintenanceRequestData.ref"
        @realoadData="realoadData = $event"
        :maintenanceContract="maintenanceRequestData.maintenance_contract"
        />
        <rejection-modal
          :modelRef="maintenanceRequestData.ref"
          :modelId="maintenanceRequestData.id"
          :modelType="maintenanceRequestData.modelType"
        />
        
      </div>
    </div>
    </Layout>
</template>
<style >
  .dropzone {
    min-height: 5px;
    padding: 0px;
  }
</style>